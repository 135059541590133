import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import TeamCard from "../components/team-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import SvgFanHp from "../components/svg/fan-svg-hp";
import SvgFanFi from "../components/svg/fan-svg-fi";
import useOnScreen from "../components/use-on-screen";
import gsap from "gsap";

const AboutEastbourneCarnivalPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			minaImg: wpMediaItem(title: { eq: "Mina-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			nigelImg: wpMediaItem(title: { eq: "Nigel-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			markImg: wpMediaItem(title: { eq: "Mark-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			christinaImg: wpMediaItem(title: { eq: "Christina-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			stephenImg: wpMediaItem(title: { eq: "Stephen-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			marcusImg: wpMediaItem(title: { eq: "Marcus-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			andyImg: wpMediaItem(title: { eq: "Andy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			lukeImg: wpMediaItem(title: { eq: "luke-image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const minaImg = data.minaImg?.localFile.childImageSharp.gatsbyImageData;
	const nigelImg = data.nigelImg?.localFile.childImageSharp.gatsbyImageData;
	const markImg = data.markImg?.localFile.childImageSharp.gatsbyImageData;
	const christinaImg =
		data.christinaImg?.localFile.childImageSharp.gatsbyImageData;
	const stephenImg = data.stephenImg?.localFile.childImageSharp.gatsbyImageData;
	const marcusImg = data.marcusImg.localFile.childImageSharp.gatsbyImageData;
	const andyImg = data.andyImg.localFile.childImageSharp.gatsbyImageData;
	const lukeImg = data.lukeImg.localFile.childImageSharp.gatsbyImageData;

	const ref1 = useRef();

	const onScreen1 = useOnScreen(ref1, "300px");

	useEffect(() => {
		if (onScreen1 === true) {
			gsap.to(".card-1", {
				transform: "scale(1)",
				duration: 1,
			});
			gsap.to(".card-2", {
				transform: "scale(1)",
				duration: 1,
				delay: 0.5,
			});
			gsap.to(".card-3", {
				transform: "scale(1)",
				duration: 1,
				delay: 1,
			});
		}
	}, [onScreen1]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Eastbourne Carnival",
				item: {
					url: `${siteUrl}/about-eastbourne-carnival`,
					id: `${siteUrl}/about-eastbourne-carnival`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="About Eastbourne Carnival | Eastbourne Carnival"
				description="Eastbourne Carnival is a community organisation, organised by a volunteer committee."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-eastbourne-carnival`,
					title: "About Eastbourne Carnival | Eastbourne Carnival",
					description:
						"Eastbourne Carnival is a community organisation, organised by a volunteer committee.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<section className="pt-5  position-relative">
				<SvgFanHp
					style={{ zIndex: -1, opacity: 0.1, height: "80%", width: "auto" }}
					className="position-absolute d-none d-md-block  top-0 start-60 translate-middle-x "
				/>
				<Container>
					<Row className="justify-content-center pb-7">
						<Col lg={8} xl={6}>
							<h1 className=" display-4 pb-4 carnival-normal text-secondary">
								About Eastbourne Carnival
							</h1>

							<p>
								Eastbourne Carnival CIC is a Community Interest Company with
								directors, and a Committee of Volunteers.
							</p>
							<p>
								Most of the committee have been involved with Carnival since it
								came back in 2011.
							</p>
							<p>
								You can make enquiries, suggestions and contact us via email, or
								via our social media channels.
							</p>
						</Col>
					</Row>

					<Row className="position-relative">
						<TeamCard
							animId="card-1"
							img={minaImg}
							imgAlt={data.minaImg?.altText}
							name="Mina O'Brien"
							role="Chair/Director"
							bgColor="bg-primary"
							text={
								<span>
									<p>
										Originally from London, but living in Eastbourne since 2004,
										Mina is a true Carnivalista, having been a participant in
										Notting Hill Carnival quite literally birth – and has gone
										on to participate in costume in Notting Hill, Trinidad, and
										Toronto Carnivals.
									</p>
									<p>
										Mina has been involved with Eastbourne Carnival since 2016,
										supporting Carnival with their social media.
									</p>
									<p>
										Stephen Lloyd stepped down after 10 years in 2021, and Mina
										is delighted to be the new Chair, building on the work done
										by Stephen and the Committee.
									</p>
									<p>
										Mina is an active member of the Eastbourne business
										community, with her own business, Ditzy Media which provides
										social media marketing and training to local businesses and
										non-profits. She is also a Director of Eastbourne Chamber of
										Commerce, and Eastbourne & District Enterprise Agency Ltd.
									</p>
								</span>
							}
						/>
						<div
							style={{ top: "400px" }}
							className="position-absolute"
							ref={ref1}
						></div>
						<TeamCard
							animId="card-2"
							img={christinaImg}
							imgAlt={data.christinaImg?.altText}
							name="Christina Ewbank"
							role="Committee"
							bgColor="bg-secondary"
							textColor="text-white"
							text={
								<span>
									<p>
										Christina joined the Committee in 2018 and is well known in
										Eastbourne as Chief Executive of Eastbourne Chamber of
										Commerce, and Director of Eastbourne & District Enterprise
										Agency Ltd.
									</p>
									<p>
										Christina brings her knowledge of the local community in
										Eastbourne, having been involved in organising other key
										events in the town, together with her passion and enthusiasm
										for Carnival.
									</p>
								</span>
							}
						/>
						<TeamCard
							animId="card-3"
							img={marcusImg}
							imgAlt={data.marcusImg.altText}
							name="Marcus O'Brien"
							role="Committee"
							bgColor="bg-primary"
							text={
								<span>
									<p>
										Marcus joined the Committee in 2019, and brings his
										technical knowledge and experience of working as a Sound
										Engineer at Notting Hill Carnival – winning best Sound in
										2013 at Notting Hill Carnival for Sunshine International
										Arts
									</p>
									<p>
										Marcus is Trinidadian and has been an active participant in
										Notting Hill Carnival for many years, and his family have
										long roots in Carnivals in Trinidad and Toronto.
									</p>
								</span>
							}
						/>
						<TeamCard
							animId="card-3"
							img={markImg}
							imgAlt={data.markImg?.altText}
							name="Mark Marsden"
							role="Committee"
							bgColor="bg-secondary"
							textColor="text-white"
							text={
								<span>
									<p>
										Born and raised in Eastbourne, Mark has two children and is
										married to Tracey. Mark retired from East Sussex Fire and
										Rescue Service in 2014. Mark brings a wealth of experience
										in Health and Safety.
									</p>
									<p>
										Mark is responsible for the Health and Safety of our event -
										crucial during a Carnival to make sure everyone is safe.
										Mark is also currently helping with Eastbourne's major other
										tourism events, including Airbourne, Eastbourne’s
										International Tennis Tournament and The Beachy Head
										Marathon.
									</p>
									<p>
										In his spare time, Mark enjoys kayaking off Eastbourne's
										seafront and is an open water scuba diving instructor with
										Eastbourne Sub Aqua Club!
									</p>
								</span>
							}
						/>
						<TeamCard
							animId="card-3"
							img={nigelImg}
							imgAlt={data.nigelImg?.altText}
							name="Nigel Hill"
							role="Committee/Director"
							bgColor="bg-primary"
							text={
								<span>
									<p>
										Nigel helped is a founding member of the Committee and is
										our Senior Marshall. As well as the eyes on the street for
										the day, he looks after Logistics and Traffic Management
										along with the Victoria Baptist Church Event Team which he
										heads.
									</p>
									<p>
										Nigel has vast experience of marshalling from his days
										working with Sussex Police. He and the Victoria Baptist
										Church Event Team currently volunteer at 10 events in the
										town each year, as well as running his own business, Nigel's
										Paint Pot {"(Painting and Decorating)"}.
									</p>
								</span>
							}
						/>

						<TeamCard
							animId="card-3"
							img={andyImg}
							imgAlt={data.andyImg?.altText}
							name="Andy Kybet"
							role="Committee/Director"
							bgColor="bg-primary"
							text={
								<span>
									<p>
										An award-winning & altruistic entrepreneur with years of
										experience in the events industry and the founder of
										Eastbourne’s premier Comic Con, WynterCon!
									</p>
									<p>
										A confident team leader, Andy ensures that the ongoing
										projects and experiences the charity delivers enrich and
										enhance people’s lives. He is also a published author, an
										avid gamer and husband/father.
									</p>
								</span>
							}
						/>
						<TeamCard
							animId="card-3"
							img={lukeImg}
							imgAlt={data.lukeImg?.altText}
							name="Luke Johnson"
							role="Committee"
							bgColor="bg-primary"
							text={
								<span>
									<p>
										Luke has lived in Eastbourne all his life and has played a
										leading role in steering our Town Centre forward over the
										past 9 years, whilst working as part of Edeal’s town centre
										management team, and more recently with Your Eastbourne BID.
									</p>
									<p>
										Luke currently heads the operations management of the BID
										and oversees most of the day to day goings on in the Town
										Centre, encompassing events, markets, street trading,
										promotions, street dressing and security.
									</p>
									<p>
										Luke has managed some major projects over the years for our
										town – most recently the Ice Rink and Jubilee street party,
										and also Neon Noel, Bike Nites and the Victoria Place Pop-up
										Parks.
									</p>
								</span>
							}
						/>
						{/* <TeamCard
							img={null}
							name="Julie Ashford"
							role="Committee"
							bgColor="bg-primary"
							text={null}
						/> */}

						{/* <TeamCard
							textColor="text-white"
							img={null}
							name="Leanna Forse"
							role="Committee"
							bgColor="bg-secondary"
							text={
								<span>
									<p>
										A parent carer and teacher, Eastbourne born and bred, Leanna
										first became involved in Eastbourne Carnival in 2019, when
										she organised the carnival 'village fete' in the town centre
										and several entertainment sites along the route. Since then,
										Leanna has been responsible for the static entertainment
										sites on carnival day.
									</p>
									<p>
										Leanna has also previously volunteered in the organisation
										of entertainment for Eastbourne Christmas and arranged
										several events for a local charity. From 2019 to 2021,
										Leanna was the marketing executive for Your Eastbourne BID,
										EDEAL, and Eastbourne Chamber of Commerce. She is now
										teaching in a local college.
									</p>
								</span>
							}
						/> */}
					</Row>
				</Container>
			</section>
			<section className="pb-md-10 pb-5">
				<Container>
					<Row>
						<Col className="text-center py-5 py-md-0 ">
							<h2 className="display-3 text-secondary carnival-normal mt-lg-5 pt-md-10 pb-4">
								History of Eastbourne
								<span className="position-relative">
									{" "}
									Carnival
									<SvgFanFi
										style={{ width: "150px", height: "150px" }}
										className="position-absolute d-none d-lg-block bottom-50 start-50 translate-middle-x"
									/>
								</span>{" "}
							</h2>
							<p className="text-start">
								The earliest reference to Carnival in Eastbourne was in 1895
								when the town celebrated ‘The Battle of the Flowers’ in May of
								that year.
							</p>
							<p className="text-start">
								Eastbourne has historically had a town carnival for decades in
								the 20th century but did have an absence of 20 years until
								Stephen Lloyd, then MP for Eastbourne brought it back in 2011.
							</p>
							<p className="text-start">
								Carnival has been affected by the pandemic and did not take
								place in 2020, and a smaller parade took place in September
								2021.
							</p>
							<p className="text-start">
								Eastbourne Carnival has held successful big parades in 2022 and
								2023, celebrating the Queen’s Platinum Jubilee (2022), and
								Sussex By The Sea (2023).
							</p>
							<p className="text-start">
								We are now back to our usual late May Bank Holiday spot on the
								Saturday – and the theme this year is ‘Into The Wild’.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<BottomBanner
				mainBg="bg-secondary"
				position="top--20"
				content={
					<Row>
						<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
							<h2 className="text-white text-center  fs-1 shortstack">
								FOLLOW US ON SOCIAL MEDIA
							</h2>
							<div className="d-flex pt-4 justify-content-center align-items-center">
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.facebook.com/eastbournecarnival"
								>
									<FaFacebookF className="text-white display-2 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://twitter.com/EBCarnival"
								>
									<FaTwitter className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.instagram.com/eastbournecarnival/"
								>
									<FaInstagram className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.youtube.com/@eastbournecarnival990"
								>
									<FaYoutube className="text-white display-2 ms-5 link-white" />
								</a>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export default AboutEastbourneCarnivalPage;
